import { userAPIClient } from "clients";
import { useCallback, useEffect, useState } from "react";
import constate from "constate";
import { useQuery } from "react-query";
import { TwirpError } from "twirp-ts";
import { GetProfileResponse } from "proto/user/v1/user_api";

const useGetProfile = () => {
  const fetchData = useCallback(() => {
    return new Promise<GetProfileResponse>(async (resolve, reject) => {
      try {
        const data = await userAPIClient.GetProfile({});
        resolve(data);
      } catch (error) {
        console.log("useGetProfile error", error);
        reject(error as TwirpError);
      }
    });
  }, []);
  const { isLoading, isFetching, isError, error, data, refetch } = useQuery<
    GetProfileResponse,
    TwirpError
  >("useGetProfileGlobal", fetchData, {
    keepPreviousData: true,
  });

  return {
    isLoading: isLoading || isFetching,
    isError,
    error,
    data,
    refetch,
  };
};

const useGlobalState = () => {
  const [loaded, setLoaded] = useState(false);
  const { data, error, isError, isLoading, refetch } = useGetProfile();

  // we will only show loading for the time we fetch data
  useEffect(() => {
    if (loaded || isLoading) return;
    setLoaded(true);
  }, [loaded, isLoading]);

  return {
    data,
    error,
    isError,
    loaded,
    isFetching: isLoading,
    refetch,
  };
};

const [GlobalStateProvider, useGlobalStateContext] = constate(useGlobalState);

export { GlobalStateProvider, useGlobalStateContext };
