import { ReactNode } from "react";
import { Link } from "react-router-dom";
import { useGlobalStateContext } from "hooks/useGlobalState";
import { DisplayError, Loading } from "components";
import { useLogout } from "hooks";
import {
  Avatar,
  Box,
  Button,
  chakra,
  Flex,
  HStack,
  IconButton,
  MenuButton,
  MenuItem,
  MenuList,
  Menu,
  useColorModeValue,
  useDisclosure,
  Center,
  MenuGroup,
  Text,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  Icon,
} from "@chakra-ui/react";
import React from "react";
import { AiOutlineMenu } from "react-icons/ai";
import {
  MdInsertInvitation,
  MdOutlineGroup,
  MdSpaceDashboard,
} from "react-icons/md";
import { FaChalkboardTeacher } from "react-icons/fa";
import { protoUserName } from "helpers";

const allLinks = [
  {
    label: "Dashboard",
    icon: <MdSpaceDashboard />,
    iconPlain: MdSpaceDashboard,
    link: "/",
  },
  {
    label: "Plans",
    icon: <FaChalkboardTeacher />,
    iconPlain: FaChalkboardTeacher,
    link: "/plans",
  },
  {
    label: "Coupons",
    icon: <MdOutlineGroup />,
    iconPlain: MdOutlineGroup,
    link: "/coupons",
  },
  {
    label: "Templates",
    icon: <MdOutlineGroup />,
    iconPlain: MdOutlineGroup,
    link: "/templates",
  },
  {
    label: "Subscrptions",
    icon: <MdInsertInvitation />,
    iconPlain: MdInsertInvitation,
    link: "/subscriptions",
  },
];

export const MainLayout = ({ children }: { children: ReactNode }) => {
  const bg = useColorModeValue("gray.50", "gray.800");
  const sidebar = useDisclosure();
  const { loaded, data, error, isError } = useGlobalStateContext();
  const onLogout = useLogout();

  if (!loaded) {
    return <Loading />;
  }
  if (isError) {
    return <DisplayError error={error} />;
  }

  const NavItem = (props: any) => {
    const { icon, children, ...rest } = props;
    return (
      <Flex
        align="center"
        px="4"
        pl="4"
        py="3"
        cursor="pointer"
        color="inherit"
        _dark={{
          color: "gray.400",
        }}
        _hover={{
          bg: "gray.100",
          _dark: {
            bg: "gray.900",
          },
          color: "gray.900",
        }}
        role="group"
        fontWeight="semibold"
        transition=".15s ease"
        {...rest}
      >
        {icon && <Icon mx="2" fontSize="lg" as={icon} color="brand.500" />}
        {children}
      </Flex>
    );
  };

  const SidebarContent = (props: any) => (
    <Box
      as="nav"
      pos="fixed"
      top="0"
      left="0"
      zIndex="sticky"
      h="full"
      pb="10"
      overflowX="hidden"
      overflowY="auto"
      bg="white"
      _dark={{
        bg: "gray.800",
      }}
      border
      color="inherit"
      borderRightWidth="1px"
      w="60"
      {...props}
    >
      <Flex px="4" py="5" align="center" justifyContent={""}>
        <Text
          fontSize="2xl"
          ml="2"
          color="brand.500"
          _dark={{
            color: "white",
          }}
          fontWeight="semibold"
        >
          Whiteboard
        </Text>
        {/* <IconButton
          ml={4}
          size="md"
          icon={<AiOutlineClose />}
          aria-label="Open Menu"
          onClick={isOpen ? onClose : onOpen}
        /> */}
      </Flex>
      <Flex
        direction="column"
        as="nav"
        fontSize="sm"
        color="gray.600"
        aria-label="Main Navigation"
      >
        {allLinks.map((l) => {
          return (
            <Link to={l.link} onClick={sidebar.onClose} key={l.link}>
              <NavItem icon={l.iconPlain}>
                <Text textColor={"brand.500"} fontSize="lg">
                  {l.label}
                </Text>
              </NavItem>
            </Link>
          );
        })}
      </Flex>
    </Box>
  );

  return (
    <Box bg={bg} minH="100vh">
      <Drawer
        isOpen={sidebar.isOpen}
        onClose={sidebar.onClose}
        placement="left"
      >
        <DrawerOverlay />
        <DrawerContent>
          <SidebarContent w="full" borderRight="none" />
        </DrawerContent>
      </Drawer>
      <React.Fragment>
        <chakra.header
          bg={"brand.500"}
          w="full"
          px={{
            base: 2,
            sm: 4,
          }}
          roundedBottom={"lg"}
        >
          <Flex
            alignItems="center"
            justifyContent="space-between"
            mx="auto"
            h={["10vh", "7vh", "7vh"]}
          >
            <HStack display="flex" spacing={3} alignItems="center" zIndex={500}>
              <Box
                display={{
                  base: "inline-flex",
                }}
              >
                <IconButton
                  display={{
                    base: "flex",
                  }}
                  aria-label="Open menu"
                  fontSize="20px"
                  color="white"
                  _dark={{
                    color: "inherit",
                  }}
                  _hover={{
                    bgColor: "",
                  }}
                  variant="filled"
                  icon={<AiOutlineMenu />}
                  onClick={sidebar.onOpen}
                />
              </Box>
            </HStack>
            <HStack>
              <Center fontSize={["2xl", "3xl", "3xl"]} color="white">
                Whiteboard
              </Center>
            </HStack>
            <HStack
              spacing={3}
              // display={sidebar.isOpen ? "none" : "flex"}
              alignItems="center"
            >
              <Menu>
                <MenuButton
                  as={Button}
                  rounded={"full"}
                  variant={"link"}
                  cursor={"pointer"}
                  minW={0}
                >
                  <Avatar bg="brand.900" size="sm" />
                </MenuButton>
                <MenuList>
                  <MenuGroup title={`Welcome ${protoUserName(data?.user)}!`}>
                    <MenuItem onClick={onLogout}>Logout</MenuItem>
                  </MenuGroup>
                </MenuList>
              </Menu>
            </HStack>
          </Flex>
        </chakra.header>
      </React.Fragment>
      {children}
    </Box>
  );
};
