// @generated by protobuf-ts 2.7.0 with parameter client_none,generate_dependencies,long_type_number,output_typescript
// @generated from protobuf file "base/v1/discount.proto" (package "base.v1", syntax proto3)
// tslint:disable
/**
 * @generated from protobuf enum base.v1.DiscountType
 */
export enum DiscountType {
    /**
     * @generated from protobuf enum value: DISCOUNT_TYPE_INVALID = 0;
     */
    INVALID = 0,
    /**
     * @generated from protobuf enum value: DISCOUNT_TYPE_FLAT = 1;
     */
    FLAT = 1,
    /**
     * @generated from protobuf enum value: DISCOUNT_TYPE_PERCENTAGE = 2;
     */
    PERCENTAGE = 2,
    /**
     * @generated from protobuf enum value: DISCOUNT_TYPE_FREE = 3;
     */
    FREE = 3
}
