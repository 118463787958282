import { Box, Text } from "@chakra-ui/react";
import { TwirpError } from "twirp-ts";

interface Props {
  size?: number;
  color?: string;
  error: TwirpError | null;
}

export const DisplayError = ({ size = 36, color = "red", error }: Props) => {
  return (
    <Box
      style={{
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        overflow: "hidden",
      }}
    >
      <Text
        py={2}
        style={{
          textAlign: "center",
          fontWeight: 700,
          fontSize: size,
        }}
        color={color}
      >
        An error has occurred: {error?.message}
      </Text>
    </Box>
  );
};
