import {
  CreateCouponRequest,
  CreateCouponResponse,
  UpdateCouponRequest,
  UpdateCouponResponse,
  DeleteCouponRequest,
  DeleteCouponResponse,
  ListCouponsRequest,
  ListCouponsResponse,
  GetCouponRequest,
  GetCouponResponse,
} from "./coupon_api";

//==================================//
//          Client Code             //
//==================================//

interface Rpc {
  request(
    service: string,
    method: string,
    contentType: "application/json" | "application/protobuf",
    data: object | Uint8Array
  ): Promise<object | Uint8Array>;
}

export interface CouponAPIClient {
  CreateCoupon(request: CreateCouponRequest): Promise<CreateCouponResponse>;
  UpdateCoupon(request: UpdateCouponRequest): Promise<UpdateCouponResponse>;
  DeleteCoupon(request: DeleteCouponRequest): Promise<DeleteCouponResponse>;
  ListCoupons(request: ListCouponsRequest): Promise<ListCouponsResponse>;
  GetCoupon(request: GetCouponRequest): Promise<GetCouponResponse>;
}

export class CouponAPIClientJSON implements CouponAPIClient {
  private readonly rpc: Rpc;
  constructor(rpc: Rpc) {
    this.rpc = rpc;
    this.CreateCoupon.bind(this);
    this.UpdateCoupon.bind(this);
    this.DeleteCoupon.bind(this);
    this.ListCoupons.bind(this);
    this.GetCoupon.bind(this);
  }
  CreateCoupon(request: CreateCouponRequest): Promise<CreateCouponResponse> {
    const data = CreateCouponRequest.toJson(request, {
      useProtoFieldName: true,
      emitDefaultValues: false,
    });
    const promise = this.rpc.request(
      "coupon.v1.CouponAPI",
      "CreateCoupon",
      "application/json",
      data as object
    );
    return promise.then((data) =>
      CreateCouponResponse.fromJson(data as any, { ignoreUnknownFields: true })
    );
  }

  UpdateCoupon(request: UpdateCouponRequest): Promise<UpdateCouponResponse> {
    const data = UpdateCouponRequest.toJson(request, {
      useProtoFieldName: true,
      emitDefaultValues: false,
    });
    const promise = this.rpc.request(
      "coupon.v1.CouponAPI",
      "UpdateCoupon",
      "application/json",
      data as object
    );
    return promise.then((data) =>
      UpdateCouponResponse.fromJson(data as any, { ignoreUnknownFields: true })
    );
  }

  DeleteCoupon(request: DeleteCouponRequest): Promise<DeleteCouponResponse> {
    const data = DeleteCouponRequest.toJson(request, {
      useProtoFieldName: true,
      emitDefaultValues: false,
    });
    const promise = this.rpc.request(
      "coupon.v1.CouponAPI",
      "DeleteCoupon",
      "application/json",
      data as object
    );
    return promise.then((data) =>
      DeleteCouponResponse.fromJson(data as any, { ignoreUnknownFields: true })
    );
  }

  ListCoupons(request: ListCouponsRequest): Promise<ListCouponsResponse> {
    const data = ListCouponsRequest.toJson(request, {
      useProtoFieldName: true,
      emitDefaultValues: false,
    });
    const promise = this.rpc.request(
      "coupon.v1.CouponAPI",
      "ListCoupons",
      "application/json",
      data as object
    );
    return promise.then((data) =>
      ListCouponsResponse.fromJson(data as any, { ignoreUnknownFields: true })
    );
  }

  GetCoupon(request: GetCouponRequest): Promise<GetCouponResponse> {
    const data = GetCouponRequest.toJson(request, {
      useProtoFieldName: true,
      emitDefaultValues: false,
    });
    const promise = this.rpc.request(
      "coupon.v1.CouponAPI",
      "GetCoupon",
      "application/json",
      data as object
    );
    return promise.then((data) =>
      GetCouponResponse.fromJson(data as any, { ignoreUnknownFields: true })
    );
  }
}

export class CouponAPIClientProtobuf implements CouponAPIClient {
  private readonly rpc: Rpc;
  constructor(rpc: Rpc) {
    this.rpc = rpc;
    this.CreateCoupon.bind(this);
    this.UpdateCoupon.bind(this);
    this.DeleteCoupon.bind(this);
    this.ListCoupons.bind(this);
    this.GetCoupon.bind(this);
  }
  CreateCoupon(request: CreateCouponRequest): Promise<CreateCouponResponse> {
    const data = CreateCouponRequest.toBinary(request);
    const promise = this.rpc.request(
      "coupon.v1.CouponAPI",
      "CreateCoupon",
      "application/protobuf",
      data
    );
    return promise.then((data) =>
      CreateCouponResponse.fromBinary(data as Uint8Array)
    );
  }

  UpdateCoupon(request: UpdateCouponRequest): Promise<UpdateCouponResponse> {
    const data = UpdateCouponRequest.toBinary(request);
    const promise = this.rpc.request(
      "coupon.v1.CouponAPI",
      "UpdateCoupon",
      "application/protobuf",
      data
    );
    return promise.then((data) =>
      UpdateCouponResponse.fromBinary(data as Uint8Array)
    );
  }

  DeleteCoupon(request: DeleteCouponRequest): Promise<DeleteCouponResponse> {
    const data = DeleteCouponRequest.toBinary(request);
    const promise = this.rpc.request(
      "coupon.v1.CouponAPI",
      "DeleteCoupon",
      "application/protobuf",
      data
    );
    return promise.then((data) =>
      DeleteCouponResponse.fromBinary(data as Uint8Array)
    );
  }

  ListCoupons(request: ListCouponsRequest): Promise<ListCouponsResponse> {
    const data = ListCouponsRequest.toBinary(request);
    const promise = this.rpc.request(
      "coupon.v1.CouponAPI",
      "ListCoupons",
      "application/protobuf",
      data
    );
    return promise.then((data) =>
      ListCouponsResponse.fromBinary(data as Uint8Array)
    );
  }

  GetCoupon(request: GetCouponRequest): Promise<GetCouponResponse> {
    const data = GetCouponRequest.toBinary(request);
    const promise = this.rpc.request(
      "coupon.v1.CouponAPI",
      "GetCoupon",
      "application/protobuf",
      data
    );
    return promise.then((data) =>
      GetCouponResponse.fromBinary(data as Uint8Array)
    );
  }
}
