// @generated by protobuf-ts 2.7.0 with parameter client_none,generate_dependencies,long_type_number,output_typescript
// @generated from protobuf file "user/v1/user_api.proto" (package "user.v1", syntax proto3)
// tslint:disable
import { ServiceType } from "@protobuf-ts/runtime-rpc";
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MESSAGE_TYPE } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
import { RoleType } from "../../base/v1/role";
import { UploadMedia } from "../../base/v1/media";
import { User } from "../../base/v1/user";
/**
 * @generated from protobuf message user.v1.GetProfileRequest
 */
export interface GetProfileRequest {
}
/**
 * @generated from protobuf message user.v1.GetProfileResponse
 */
export interface GetProfileResponse {
    /**
     * @generated from protobuf field: base.v1.User user = 1;
     */
    user?: User;
}
/**
 * @generated from protobuf message user.v1.UpdateProfileRequest
 */
export interface UpdateProfileRequest {
    /**
     * @generated from protobuf field: string first_name = 1;
     */
    firstName: string;
    /**
     * @generated from protobuf field: string last_name = 2;
     */
    lastName: string;
    /**
     * @generated from protobuf field: base.v1.UploadMedia avatar = 3;
     */
    avatar?: UploadMedia;
}
/**
 * @generated from protobuf message user.v1.UpdateProfileResponse
 */
export interface UpdateProfileResponse {
}
/**
 * @generated from protobuf message user.v1.ListAllUsersRequest
 */
export interface ListAllUsersRequest {
    /**
     * @generated from protobuf field: repeated base.v1.RoleType roles = 1;
     */
    roles: RoleType[];
}
/**
 * @generated from protobuf message user.v1.ListAllUsersResponse
 */
export interface ListAllUsersResponse {
    /**
     * @generated from protobuf field: repeated base.v1.User users = 1;
     */
    users: User[];
}
// @generated message type with reflection information, may provide speed optimized methods
class GetProfileRequest$Type extends MessageType<GetProfileRequest> {
    constructor() {
        super("user.v1.GetProfileRequest", []);
    }
    create(value?: PartialMessage<GetProfileRequest>): GetProfileRequest {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetProfileRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetProfileRequest): GetProfileRequest {
        return target ?? this.create();
    }
    internalBinaryWrite(message: GetProfileRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message user.v1.GetProfileRequest
 */
export const GetProfileRequest = new GetProfileRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetProfileResponse$Type extends MessageType<GetProfileResponse> {
    constructor() {
        super("user.v1.GetProfileResponse", [
            { no: 1, name: "user", kind: "message", T: () => User }
        ]);
    }
    create(value?: PartialMessage<GetProfileResponse>): GetProfileResponse {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetProfileResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetProfileResponse): GetProfileResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* base.v1.User user */ 1:
                    message.user = User.internalBinaryRead(reader, reader.uint32(), options, message.user);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetProfileResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* base.v1.User user = 1; */
        if (message.user)
            User.internalBinaryWrite(message.user, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message user.v1.GetProfileResponse
 */
export const GetProfileResponse = new GetProfileResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdateProfileRequest$Type extends MessageType<UpdateProfileRequest> {
    constructor() {
        super("user.v1.UpdateProfileRequest", [
            { no: 1, name: "first_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "last_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "avatar", kind: "message", T: () => UploadMedia }
        ]);
    }
    create(value?: PartialMessage<UpdateProfileRequest>): UpdateProfileRequest {
        const message = { firstName: "", lastName: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<UpdateProfileRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UpdateProfileRequest): UpdateProfileRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string first_name */ 1:
                    message.firstName = reader.string();
                    break;
                case /* string last_name */ 2:
                    message.lastName = reader.string();
                    break;
                case /* base.v1.UploadMedia avatar */ 3:
                    message.avatar = UploadMedia.internalBinaryRead(reader, reader.uint32(), options, message.avatar);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: UpdateProfileRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string first_name = 1; */
        if (message.firstName !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.firstName);
        /* string last_name = 2; */
        if (message.lastName !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.lastName);
        /* base.v1.UploadMedia avatar = 3; */
        if (message.avatar)
            UploadMedia.internalBinaryWrite(message.avatar, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message user.v1.UpdateProfileRequest
 */
export const UpdateProfileRequest = new UpdateProfileRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdateProfileResponse$Type extends MessageType<UpdateProfileResponse> {
    constructor() {
        super("user.v1.UpdateProfileResponse", []);
    }
    create(value?: PartialMessage<UpdateProfileResponse>): UpdateProfileResponse {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<UpdateProfileResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UpdateProfileResponse): UpdateProfileResponse {
        return target ?? this.create();
    }
    internalBinaryWrite(message: UpdateProfileResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message user.v1.UpdateProfileResponse
 */
export const UpdateProfileResponse = new UpdateProfileResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListAllUsersRequest$Type extends MessageType<ListAllUsersRequest> {
    constructor() {
        super("user.v1.ListAllUsersRequest", [
            { no: 1, name: "roles", kind: "enum", repeat: 1 /*RepeatType.PACKED*/, T: () => ["base.v1.RoleType", RoleType, "ROLE_TYPE_"] }
        ]);
    }
    create(value?: PartialMessage<ListAllUsersRequest>): ListAllUsersRequest {
        const message = { roles: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ListAllUsersRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListAllUsersRequest): ListAllUsersRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated base.v1.RoleType roles */ 1:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.roles.push(reader.int32());
                    else
                        message.roles.push(reader.int32());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListAllUsersRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated base.v1.RoleType roles = 1; */
        if (message.roles.length) {
            writer.tag(1, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.roles.length; i++)
                writer.int32(message.roles[i]);
            writer.join();
        }
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message user.v1.ListAllUsersRequest
 */
export const ListAllUsersRequest = new ListAllUsersRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListAllUsersResponse$Type extends MessageType<ListAllUsersResponse> {
    constructor() {
        super("user.v1.ListAllUsersResponse", [
            { no: 1, name: "users", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => User }
        ]);
    }
    create(value?: PartialMessage<ListAllUsersResponse>): ListAllUsersResponse {
        const message = { users: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ListAllUsersResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListAllUsersResponse): ListAllUsersResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated base.v1.User users */ 1:
                    message.users.push(User.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListAllUsersResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated base.v1.User users = 1; */
        for (let i = 0; i < message.users.length; i++)
            User.internalBinaryWrite(message.users[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message user.v1.ListAllUsersResponse
 */
export const ListAllUsersResponse = new ListAllUsersResponse$Type();
/**
 * @generated ServiceType for protobuf service user.v1.UserAPI
 */
export const UserAPI = new ServiceType("user.v1.UserAPI", [
    { name: "GetProfile", options: {}, I: GetProfileRequest, O: GetProfileResponse },
    { name: "UpdateProfile", options: {}, I: UpdateProfileRequest, O: UpdateProfileResponse },
    { name: "ListAllUsers", options: { "base.v1.method_permission": { allowedRoles: ["ROLE_TYPE_ADMIN"] } }, I: ListAllUsersRequest, O: ListAllUsersResponse }
], { "base.v1.service_permission": { requireAuth: true } });
