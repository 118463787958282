import { chakra, Container } from "@chakra-ui/react";
import { Loading } from "components";
import { StateProvider } from "hooks/provider";
import React from "react";
import { Route, Routes } from "react-router-dom";
import { EmailPasswordAuth } from "supertokens-auth-react/recipe/emailpassword";
import { MainLayout } from "./layout";

const MainDashboardPage = React.lazy(
  () => import("./dashboard/pages/MainDashboardPage")
);

const PlansPage = React.lazy(() => import("./plan/pages/PlansPage"));
const CreatePlanPage = React.lazy(() => import("./plan/pages/CreatePlanPage"));
const EditPlanPage = React.lazy(() => import("./plan/pages/EditPlanPage"));

const CouponsPage = React.lazy(() => import("./coupon/pages/CouponsPage"));
const CreateCouponPage = React.lazy(
  () => import("./coupon/pages/CreateCouponPage")
);
const EditCouponPage = React.lazy(
  () => import("./coupon/pages/EditCouponPage")
);

const TemplatesPage = React.lazy(
  () => import("./template/pages/TemplatesPage")
);
const CreateTemplatePage = React.lazy(
  () => import("./template/pages/CreateTemplatePage")
);

const SubscriptionsPage = React.lazy(
  () => import("./subscription/pages/SubscriptionsPage")
);
const CreateSubscriptionPage = React.lazy(
  () => import("./subscription/pages/CreateSubscriptionPage")
);

const LoadingFallback = () => {
  return (
    <Container>
      <Loading text="" />
    </Container>
  );
};

export const Main = () => {
  return (
    <EmailPasswordAuth>
      <StateProvider>
        <MainLayout>
          <chakra.div pt={4}>
            <Routes>
              <Route
                index
                element={
                  <React.Suspense fallback={<LoadingFallback />}>
                    <MainDashboardPage />
                  </React.Suspense>
                }
              />
              <Route
                path="/plans"
                element={
                  <React.Suspense fallback={<LoadingFallback />}>
                    <PlansPage />
                  </React.Suspense>
                }
              />
              <Route
                path="/plans/new"
                element={
                  <React.Suspense fallback={<LoadingFallback />}>
                    <CreatePlanPage />
                  </React.Suspense>
                }
              />
              <Route
                path="/plan/:id"
                element={
                  <React.Suspense fallback={<LoadingFallback />}>
                    <EditPlanPage />
                  </React.Suspense>
                }
              />
              <Route
                path="/coupons"
                element={
                  <React.Suspense fallback={<LoadingFallback />}>
                    <CouponsPage />
                  </React.Suspense>
                }
              />
              <Route
                path="/coupons/new"
                element={
                  <React.Suspense fallback={<LoadingFallback />}>
                    <CreateCouponPage />
                  </React.Suspense>
                }
              />
              <Route
                path="/coupon/:id"
                element={
                  <React.Suspense fallback={<LoadingFallback />}>
                    <EditCouponPage />
                  </React.Suspense>
                }
              />
              <Route
                path="/templates"
                element={
                  <React.Suspense fallback={<LoadingFallback />}>
                    <TemplatesPage />
                  </React.Suspense>
                }
              />
              <Route
                path="/templates/new"
                element={
                  <React.Suspense fallback={<LoadingFallback />}>
                    <CreateTemplatePage />
                  </React.Suspense>
                }
              />

              <Route
                path="/subscriptions"
                element={
                  <React.Suspense fallback={<LoadingFallback />}>
                    <SubscriptionsPage />
                  </React.Suspense>
                }
              />
              <Route
                path="/subscriptions/new"
                element={
                  <React.Suspense fallback={<LoadingFallback />}>
                    <CreateSubscriptionPage />
                  </React.Suspense>
                }
              />
            </Routes>
          </chakra.div>
        </MainLayout>
      </StateProvider>
    </EmailPasswordAuth>
  );
};
