// @generated by protobuf-ts 2.7.0 with parameter client_none,generate_dependencies,long_type_number,output_typescript
// @generated from protobuf file "coupon/v1/coupon_api.proto" (package "coupon.v1", syntax proto3)
// tslint:disable
import { ServiceType } from "@protobuf-ts/runtime-rpc";
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MESSAGE_TYPE } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
import { Coupon } from "./coupon";
import { SortDirection } from "../../base/v1/sort";
import { DiscountType } from "../../base/v1/discount";
import { Timestamp } from "../../google/protobuf/timestamp";
/**
 * @generated from protobuf message coupon.v1.CreateCouponRequest
 */
export interface CreateCouponRequest {
    /**
     * @generated from protobuf field: string value = 1;
     */
    value: string;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp active_since = 2;
     */
    activeSince?: Timestamp;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp active_till = 3;
     */
    activeTill?: Timestamp;
    /**
     * @generated from protobuf field: int32 max_usage = 4;
     */
    maxUsage: number;
    /**
     * @generated from protobuf field: int32 max_usage_per_user = 5;
     */
    maxUsagePerUser: number;
    /**
     * @generated from protobuf field: int32 max_users = 6;
     */
    maxUsers: number;
    /**
     * @generated from protobuf field: double discount_value = 7;
     */
    discountValue: number;
    /**
     * @generated from protobuf field: double minimum_value = 8;
     */
    minimumValue: number;
    /**
     * @generated from protobuf field: base.v1.DiscountType discount_type = 9;
     */
    discountType: DiscountType;
}
/**
 * @generated from protobuf message coupon.v1.CreateCouponResponse
 */
export interface CreateCouponResponse {
    /**
     * @generated from protobuf field: string id = 1;
     */
    id: string;
}
/**
 * @generated from protobuf message coupon.v1.UpdateCouponRequest
 */
export interface UpdateCouponRequest {
    /**
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * @generated from protobuf field: string value = 2;
     */
    value: string;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp active_since = 3;
     */
    activeSince?: Timestamp;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp active_till = 4;
     */
    activeTill?: Timestamp;
    /**
     * @generated from protobuf field: int32 max_usage = 5;
     */
    maxUsage: number;
    /**
     * @generated from protobuf field: int32 max_usage_per_user = 6;
     */
    maxUsagePerUser: number;
    /**
     * @generated from protobuf field: int32 max_users = 7;
     */
    maxUsers: number;
    /**
     * @generated from protobuf field: double discount_value = 8;
     */
    discountValue: number;
    /**
     * @generated from protobuf field: double minimum_value = 9;
     */
    minimumValue: number;
    /**
     * @generated from protobuf field: base.v1.DiscountType discount_type = 10;
     */
    discountType: DiscountType;
    /**
     * @generated from protobuf field: bool enabled = 11;
     */
    enabled: boolean;
}
/**
 * @generated from protobuf message coupon.v1.UpdateCouponResponse
 */
export interface UpdateCouponResponse {
}
/**
 * @generated from protobuf message coupon.v1.ListCouponsRequest
 */
export interface ListCouponsRequest {
    /**
     * which page to list
     *
     * @generated from protobuf field: int32 page = 1;
     */
    page: number;
    /**
     * size of the page
     *
     * @generated from protobuf field: int32 page_size = 2;
     */
    pageSize: number;
    /**
     * sort direction
     *
     * @generated from protobuf field: base.v1.SortDirection sort_direction = 3;
     */
    sortDirection: SortDirection;
}
/**
 * @generated from protobuf message coupon.v1.ListCouponsResponse
 */
export interface ListCouponsResponse {
    /**
     * @generated from protobuf field: repeated coupon.v1.Coupon coupons = 1;
     */
    coupons: Coupon[];
}
/**
 * @generated from protobuf message coupon.v1.GetCouponRequest
 */
export interface GetCouponRequest {
    /**
     * @generated from protobuf field: string coupon_id = 1;
     */
    couponId: string;
}
/**
 * @generated from protobuf message coupon.v1.GetCouponResponse
 */
export interface GetCouponResponse {
    /**
     * @generated from protobuf field: coupon.v1.Coupon coupon = 1;
     */
    coupon?: Coupon;
}
/**
 * @generated from protobuf message coupon.v1.DeleteCouponRequest
 */
export interface DeleteCouponRequest {
    /**
     * @generated from protobuf field: string coupon_id = 1;
     */
    couponId: string;
}
/**
 * @generated from protobuf message coupon.v1.DeleteCouponResponse
 */
export interface DeleteCouponResponse {
}
// @generated message type with reflection information, may provide speed optimized methods
class CreateCouponRequest$Type extends MessageType<CreateCouponRequest> {
    constructor() {
        super("coupon.v1.CreateCouponRequest", [
            { no: 1, name: "value", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "active_since", kind: "message", T: () => Timestamp },
            { no: 3, name: "active_till", kind: "message", T: () => Timestamp },
            { no: 4, name: "max_usage", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 5, name: "max_usage_per_user", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 6, name: "max_users", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 7, name: "discount_value", kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 8, name: "minimum_value", kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 9, name: "discount_type", kind: "enum", T: () => ["base.v1.DiscountType", DiscountType, "DISCOUNT_TYPE_"] }
        ]);
    }
    create(value?: PartialMessage<CreateCouponRequest>): CreateCouponRequest {
        const message = { value: "", maxUsage: 0, maxUsagePerUser: 0, maxUsers: 0, discountValue: 0, minimumValue: 0, discountType: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<CreateCouponRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CreateCouponRequest): CreateCouponRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string value */ 1:
                    message.value = reader.string();
                    break;
                case /* google.protobuf.Timestamp active_since */ 2:
                    message.activeSince = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.activeSince);
                    break;
                case /* google.protobuf.Timestamp active_till */ 3:
                    message.activeTill = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.activeTill);
                    break;
                case /* int32 max_usage */ 4:
                    message.maxUsage = reader.int32();
                    break;
                case /* int32 max_usage_per_user */ 5:
                    message.maxUsagePerUser = reader.int32();
                    break;
                case /* int32 max_users */ 6:
                    message.maxUsers = reader.int32();
                    break;
                case /* double discount_value */ 7:
                    message.discountValue = reader.double();
                    break;
                case /* double minimum_value */ 8:
                    message.minimumValue = reader.double();
                    break;
                case /* base.v1.DiscountType discount_type */ 9:
                    message.discountType = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CreateCouponRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string value = 1; */
        if (message.value !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.value);
        /* google.protobuf.Timestamp active_since = 2; */
        if (message.activeSince)
            Timestamp.internalBinaryWrite(message.activeSince, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.Timestamp active_till = 3; */
        if (message.activeTill)
            Timestamp.internalBinaryWrite(message.activeTill, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* int32 max_usage = 4; */
        if (message.maxUsage !== 0)
            writer.tag(4, WireType.Varint).int32(message.maxUsage);
        /* int32 max_usage_per_user = 5; */
        if (message.maxUsagePerUser !== 0)
            writer.tag(5, WireType.Varint).int32(message.maxUsagePerUser);
        /* int32 max_users = 6; */
        if (message.maxUsers !== 0)
            writer.tag(6, WireType.Varint).int32(message.maxUsers);
        /* double discount_value = 7; */
        if (message.discountValue !== 0)
            writer.tag(7, WireType.Bit64).double(message.discountValue);
        /* double minimum_value = 8; */
        if (message.minimumValue !== 0)
            writer.tag(8, WireType.Bit64).double(message.minimumValue);
        /* base.v1.DiscountType discount_type = 9; */
        if (message.discountType !== 0)
            writer.tag(9, WireType.Varint).int32(message.discountType);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message coupon.v1.CreateCouponRequest
 */
export const CreateCouponRequest = new CreateCouponRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CreateCouponResponse$Type extends MessageType<CreateCouponResponse> {
    constructor() {
        super("coupon.v1.CreateCouponResponse", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<CreateCouponResponse>): CreateCouponResponse {
        const message = { id: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<CreateCouponResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CreateCouponResponse): CreateCouponResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CreateCouponResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message coupon.v1.CreateCouponResponse
 */
export const CreateCouponResponse = new CreateCouponResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdateCouponRequest$Type extends MessageType<UpdateCouponRequest> {
    constructor() {
        super("coupon.v1.UpdateCouponRequest", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "value", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "active_since", kind: "message", T: () => Timestamp },
            { no: 4, name: "active_till", kind: "message", T: () => Timestamp },
            { no: 5, name: "max_usage", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 6, name: "max_usage_per_user", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 7, name: "max_users", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 8, name: "discount_value", kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 9, name: "minimum_value", kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 10, name: "discount_type", kind: "enum", T: () => ["base.v1.DiscountType", DiscountType, "DISCOUNT_TYPE_"] },
            { no: 11, name: "enabled", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value?: PartialMessage<UpdateCouponRequest>): UpdateCouponRequest {
        const message = { id: "", value: "", maxUsage: 0, maxUsagePerUser: 0, maxUsers: 0, discountValue: 0, minimumValue: 0, discountType: 0, enabled: false };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<UpdateCouponRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UpdateCouponRequest): UpdateCouponRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                case /* string value */ 2:
                    message.value = reader.string();
                    break;
                case /* google.protobuf.Timestamp active_since */ 3:
                    message.activeSince = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.activeSince);
                    break;
                case /* google.protobuf.Timestamp active_till */ 4:
                    message.activeTill = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.activeTill);
                    break;
                case /* int32 max_usage */ 5:
                    message.maxUsage = reader.int32();
                    break;
                case /* int32 max_usage_per_user */ 6:
                    message.maxUsagePerUser = reader.int32();
                    break;
                case /* int32 max_users */ 7:
                    message.maxUsers = reader.int32();
                    break;
                case /* double discount_value */ 8:
                    message.discountValue = reader.double();
                    break;
                case /* double minimum_value */ 9:
                    message.minimumValue = reader.double();
                    break;
                case /* base.v1.DiscountType discount_type */ 10:
                    message.discountType = reader.int32();
                    break;
                case /* bool enabled */ 11:
                    message.enabled = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: UpdateCouponRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* string value = 2; */
        if (message.value !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.value);
        /* google.protobuf.Timestamp active_since = 3; */
        if (message.activeSince)
            Timestamp.internalBinaryWrite(message.activeSince, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.Timestamp active_till = 4; */
        if (message.activeTill)
            Timestamp.internalBinaryWrite(message.activeTill, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* int32 max_usage = 5; */
        if (message.maxUsage !== 0)
            writer.tag(5, WireType.Varint).int32(message.maxUsage);
        /* int32 max_usage_per_user = 6; */
        if (message.maxUsagePerUser !== 0)
            writer.tag(6, WireType.Varint).int32(message.maxUsagePerUser);
        /* int32 max_users = 7; */
        if (message.maxUsers !== 0)
            writer.tag(7, WireType.Varint).int32(message.maxUsers);
        /* double discount_value = 8; */
        if (message.discountValue !== 0)
            writer.tag(8, WireType.Bit64).double(message.discountValue);
        /* double minimum_value = 9; */
        if (message.minimumValue !== 0)
            writer.tag(9, WireType.Bit64).double(message.minimumValue);
        /* base.v1.DiscountType discount_type = 10; */
        if (message.discountType !== 0)
            writer.tag(10, WireType.Varint).int32(message.discountType);
        /* bool enabled = 11; */
        if (message.enabled !== false)
            writer.tag(11, WireType.Varint).bool(message.enabled);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message coupon.v1.UpdateCouponRequest
 */
export const UpdateCouponRequest = new UpdateCouponRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdateCouponResponse$Type extends MessageType<UpdateCouponResponse> {
    constructor() {
        super("coupon.v1.UpdateCouponResponse", []);
    }
    create(value?: PartialMessage<UpdateCouponResponse>): UpdateCouponResponse {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<UpdateCouponResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UpdateCouponResponse): UpdateCouponResponse {
        return target ?? this.create();
    }
    internalBinaryWrite(message: UpdateCouponResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message coupon.v1.UpdateCouponResponse
 */
export const UpdateCouponResponse = new UpdateCouponResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListCouponsRequest$Type extends MessageType<ListCouponsRequest> {
    constructor() {
        super("coupon.v1.ListCouponsRequest", [
            { no: 1, name: "page", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "page_size", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 3, name: "sort_direction", kind: "enum", T: () => ["base.v1.SortDirection", SortDirection, "SORT_DIRECTION_"] }
        ]);
    }
    create(value?: PartialMessage<ListCouponsRequest>): ListCouponsRequest {
        const message = { page: 0, pageSize: 0, sortDirection: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ListCouponsRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListCouponsRequest): ListCouponsRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 page */ 1:
                    message.page = reader.int32();
                    break;
                case /* int32 page_size */ 2:
                    message.pageSize = reader.int32();
                    break;
                case /* base.v1.SortDirection sort_direction */ 3:
                    message.sortDirection = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListCouponsRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int32 page = 1; */
        if (message.page !== 0)
            writer.tag(1, WireType.Varint).int32(message.page);
        /* int32 page_size = 2; */
        if (message.pageSize !== 0)
            writer.tag(2, WireType.Varint).int32(message.pageSize);
        /* base.v1.SortDirection sort_direction = 3; */
        if (message.sortDirection !== 0)
            writer.tag(3, WireType.Varint).int32(message.sortDirection);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message coupon.v1.ListCouponsRequest
 */
export const ListCouponsRequest = new ListCouponsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListCouponsResponse$Type extends MessageType<ListCouponsResponse> {
    constructor() {
        super("coupon.v1.ListCouponsResponse", [
            { no: 1, name: "coupons", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Coupon }
        ]);
    }
    create(value?: PartialMessage<ListCouponsResponse>): ListCouponsResponse {
        const message = { coupons: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ListCouponsResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListCouponsResponse): ListCouponsResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated coupon.v1.Coupon coupons */ 1:
                    message.coupons.push(Coupon.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListCouponsResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated coupon.v1.Coupon coupons = 1; */
        for (let i = 0; i < message.coupons.length; i++)
            Coupon.internalBinaryWrite(message.coupons[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message coupon.v1.ListCouponsResponse
 */
export const ListCouponsResponse = new ListCouponsResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetCouponRequest$Type extends MessageType<GetCouponRequest> {
    constructor() {
        super("coupon.v1.GetCouponRequest", [
            { no: 1, name: "coupon_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<GetCouponRequest>): GetCouponRequest {
        const message = { couponId: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetCouponRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetCouponRequest): GetCouponRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string coupon_id */ 1:
                    message.couponId = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetCouponRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string coupon_id = 1; */
        if (message.couponId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.couponId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message coupon.v1.GetCouponRequest
 */
export const GetCouponRequest = new GetCouponRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetCouponResponse$Type extends MessageType<GetCouponResponse> {
    constructor() {
        super("coupon.v1.GetCouponResponse", [
            { no: 1, name: "coupon", kind: "message", T: () => Coupon }
        ]);
    }
    create(value?: PartialMessage<GetCouponResponse>): GetCouponResponse {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetCouponResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetCouponResponse): GetCouponResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* coupon.v1.Coupon coupon */ 1:
                    message.coupon = Coupon.internalBinaryRead(reader, reader.uint32(), options, message.coupon);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetCouponResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* coupon.v1.Coupon coupon = 1; */
        if (message.coupon)
            Coupon.internalBinaryWrite(message.coupon, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message coupon.v1.GetCouponResponse
 */
export const GetCouponResponse = new GetCouponResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DeleteCouponRequest$Type extends MessageType<DeleteCouponRequest> {
    constructor() {
        super("coupon.v1.DeleteCouponRequest", [
            { no: 1, name: "coupon_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<DeleteCouponRequest>): DeleteCouponRequest {
        const message = { couponId: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<DeleteCouponRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DeleteCouponRequest): DeleteCouponRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string coupon_id */ 1:
                    message.couponId = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: DeleteCouponRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string coupon_id = 1; */
        if (message.couponId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.couponId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message coupon.v1.DeleteCouponRequest
 */
export const DeleteCouponRequest = new DeleteCouponRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DeleteCouponResponse$Type extends MessageType<DeleteCouponResponse> {
    constructor() {
        super("coupon.v1.DeleteCouponResponse", []);
    }
    create(value?: PartialMessage<DeleteCouponResponse>): DeleteCouponResponse {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<DeleteCouponResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DeleteCouponResponse): DeleteCouponResponse {
        return target ?? this.create();
    }
    internalBinaryWrite(message: DeleteCouponResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message coupon.v1.DeleteCouponResponse
 */
export const DeleteCouponResponse = new DeleteCouponResponse$Type();
/**
 * @generated ServiceType for protobuf service coupon.v1.CouponAPI
 */
export const CouponAPI = new ServiceType("coupon.v1.CouponAPI", [
    { name: "CreateCoupon", options: { "base.v1.method_permission": { allowedRoles: ["ROLE_TYPE_ADMIN"] } }, I: CreateCouponRequest, O: CreateCouponResponse },
    { name: "UpdateCoupon", options: { "base.v1.method_permission": { allowedRoles: ["ROLE_TYPE_ADMIN"] } }, I: UpdateCouponRequest, O: UpdateCouponResponse },
    { name: "DeleteCoupon", options: { "base.v1.method_permission": { allowedRoles: ["ROLE_TYPE_ADMIN"] } }, I: DeleteCouponRequest, O: DeleteCouponResponse },
    { name: "ListCoupons", options: { "base.v1.method_permission": { allowedRoles: ["ROLE_TYPE_ADMIN"] } }, I: ListCouponsRequest, O: ListCouponsResponse },
    { name: "GetCoupon", options: { "base.v1.method_permission": { allowedRoles: ["ROLE_TYPE_ADMIN"] } }, I: GetCouponRequest, O: GetCouponResponse }
], { "base.v1.service_permission": { requireAuth: true } });
