import {
  ListBoardsRequest,
  ListBoardsResponse,
  GetBoardRequest,
  GetBoardResponse,
  CreateBoardRequest,
  CreateBoardResponse,
  UpdateBoardDataRequest,
  UpdateBoardDataResponse,
  DeleteBoardRequest,
  DeleteBoardResponse,
  GetBoardRoomsRequest,
  GetBoardRoomsResponse,
  UpdateBoardRoomsRequest,
  UpdateBoardRoomsResponse,
} from "./board_api";

//==================================//
//          Client Code             //
//==================================//

interface Rpc {
  request(
    service: string,
    method: string,
    contentType: "application/json" | "application/protobuf",
    data: object | Uint8Array
  ): Promise<object | Uint8Array>;
}

export interface BoardAPIClient {
  ListBoards(request: ListBoardsRequest): Promise<ListBoardsResponse>;
  GetBoard(request: GetBoardRequest): Promise<GetBoardResponse>;
  CreateBoard(request: CreateBoardRequest): Promise<CreateBoardResponse>;
  UpdateBoardData(
    request: UpdateBoardDataRequest
  ): Promise<UpdateBoardDataResponse>;
  DeleteBoard(request: DeleteBoardRequest): Promise<DeleteBoardResponse>;
  GetBoardRooms(request: GetBoardRoomsRequest): Promise<GetBoardRoomsResponse>;
  UpdateBoardRooms(
    request: UpdateBoardRoomsRequest
  ): Promise<UpdateBoardRoomsResponse>;
}

export class BoardAPIClientJSON implements BoardAPIClient {
  private readonly rpc: Rpc;
  constructor(rpc: Rpc) {
    this.rpc = rpc;
    this.ListBoards.bind(this);
    this.GetBoard.bind(this);
    this.CreateBoard.bind(this);
    this.UpdateBoardData.bind(this);
    this.DeleteBoard.bind(this);
    this.GetBoardRooms.bind(this);
    this.UpdateBoardRooms.bind(this);
  }
  ListBoards(request: ListBoardsRequest): Promise<ListBoardsResponse> {
    const data = ListBoardsRequest.toJson(request, {
      useProtoFieldName: true,
      emitDefaultValues: false,
    });
    const promise = this.rpc.request(
      "whiteboard.board.v1.BoardAPI",
      "ListBoards",
      "application/json",
      data as object
    );
    return promise.then((data) =>
      ListBoardsResponse.fromJson(data as any, { ignoreUnknownFields: true })
    );
  }

  GetBoard(request: GetBoardRequest): Promise<GetBoardResponse> {
    const data = GetBoardRequest.toJson(request, {
      useProtoFieldName: true,
      emitDefaultValues: false,
    });
    const promise = this.rpc.request(
      "whiteboard.board.v1.BoardAPI",
      "GetBoard",
      "application/json",
      data as object
    );
    return promise.then((data) =>
      GetBoardResponse.fromJson(data as any, { ignoreUnknownFields: true })
    );
  }

  CreateBoard(request: CreateBoardRequest): Promise<CreateBoardResponse> {
    const data = CreateBoardRequest.toJson(request, {
      useProtoFieldName: true,
      emitDefaultValues: false,
    });
    const promise = this.rpc.request(
      "whiteboard.board.v1.BoardAPI",
      "CreateBoard",
      "application/json",
      data as object
    );
    return promise.then((data) =>
      CreateBoardResponse.fromJson(data as any, { ignoreUnknownFields: true })
    );
  }

  UpdateBoardData(
    request: UpdateBoardDataRequest
  ): Promise<UpdateBoardDataResponse> {
    const data = UpdateBoardDataRequest.toJson(request, {
      useProtoFieldName: true,
      emitDefaultValues: false,
    });
    const promise = this.rpc.request(
      "whiteboard.board.v1.BoardAPI",
      "UpdateBoardData",
      "application/json",
      data as object
    );
    return promise.then((data) =>
      UpdateBoardDataResponse.fromJson(data as any, {
        ignoreUnknownFields: true,
      })
    );
  }

  DeleteBoard(request: DeleteBoardRequest): Promise<DeleteBoardResponse> {
    const data = DeleteBoardRequest.toJson(request, {
      useProtoFieldName: true,
      emitDefaultValues: false,
    });
    const promise = this.rpc.request(
      "whiteboard.board.v1.BoardAPI",
      "DeleteBoard",
      "application/json",
      data as object
    );
    return promise.then((data) =>
      DeleteBoardResponse.fromJson(data as any, { ignoreUnknownFields: true })
    );
  }

  GetBoardRooms(request: GetBoardRoomsRequest): Promise<GetBoardRoomsResponse> {
    const data = GetBoardRoomsRequest.toJson(request, {
      useProtoFieldName: true,
      emitDefaultValues: false,
    });
    const promise = this.rpc.request(
      "whiteboard.board.v1.BoardAPI",
      "GetBoardRooms",
      "application/json",
      data as object
    );
    return promise.then((data) =>
      GetBoardRoomsResponse.fromJson(data as any, { ignoreUnknownFields: true })
    );
  }

  UpdateBoardRooms(
    request: UpdateBoardRoomsRequest
  ): Promise<UpdateBoardRoomsResponse> {
    const data = UpdateBoardRoomsRequest.toJson(request, {
      useProtoFieldName: true,
      emitDefaultValues: false,
    });
    const promise = this.rpc.request(
      "whiteboard.board.v1.BoardAPI",
      "UpdateBoardRooms",
      "application/json",
      data as object
    );
    return promise.then((data) =>
      UpdateBoardRoomsResponse.fromJson(data as any, {
        ignoreUnknownFields: true,
      })
    );
  }
}

export class BoardAPIClientProtobuf implements BoardAPIClient {
  private readonly rpc: Rpc;
  constructor(rpc: Rpc) {
    this.rpc = rpc;
    this.ListBoards.bind(this);
    this.GetBoard.bind(this);
    this.CreateBoard.bind(this);
    this.UpdateBoardData.bind(this);
    this.DeleteBoard.bind(this);
    this.GetBoardRooms.bind(this);
    this.UpdateBoardRooms.bind(this);
  }
  ListBoards(request: ListBoardsRequest): Promise<ListBoardsResponse> {
    const data = ListBoardsRequest.toBinary(request);
    const promise = this.rpc.request(
      "whiteboard.board.v1.BoardAPI",
      "ListBoards",
      "application/protobuf",
      data
    );
    return promise.then((data) =>
      ListBoardsResponse.fromBinary(data as Uint8Array)
    );
  }

  GetBoard(request: GetBoardRequest): Promise<GetBoardResponse> {
    const data = GetBoardRequest.toBinary(request);
    const promise = this.rpc.request(
      "whiteboard.board.v1.BoardAPI",
      "GetBoard",
      "application/protobuf",
      data
    );
    return promise.then((data) =>
      GetBoardResponse.fromBinary(data as Uint8Array)
    );
  }

  CreateBoard(request: CreateBoardRequest): Promise<CreateBoardResponse> {
    const data = CreateBoardRequest.toBinary(request);
    const promise = this.rpc.request(
      "whiteboard.board.v1.BoardAPI",
      "CreateBoard",
      "application/protobuf",
      data
    );
    return promise.then((data) =>
      CreateBoardResponse.fromBinary(data as Uint8Array)
    );
  }

  UpdateBoardData(
    request: UpdateBoardDataRequest
  ): Promise<UpdateBoardDataResponse> {
    const data = UpdateBoardDataRequest.toBinary(request);
    const promise = this.rpc.request(
      "whiteboard.board.v1.BoardAPI",
      "UpdateBoardData",
      "application/protobuf",
      data
    );
    return promise.then((data) =>
      UpdateBoardDataResponse.fromBinary(data as Uint8Array)
    );
  }

  DeleteBoard(request: DeleteBoardRequest): Promise<DeleteBoardResponse> {
    const data = DeleteBoardRequest.toBinary(request);
    const promise = this.rpc.request(
      "whiteboard.board.v1.BoardAPI",
      "DeleteBoard",
      "application/protobuf",
      data
    );
    return promise.then((data) =>
      DeleteBoardResponse.fromBinary(data as Uint8Array)
    );
  }

  GetBoardRooms(request: GetBoardRoomsRequest): Promise<GetBoardRoomsResponse> {
    const data = GetBoardRoomsRequest.toBinary(request);
    const promise = this.rpc.request(
      "whiteboard.board.v1.BoardAPI",
      "GetBoardRooms",
      "application/protobuf",
      data
    );
    return promise.then((data) =>
      GetBoardRoomsResponse.fromBinary(data as Uint8Array)
    );
  }

  UpdateBoardRooms(
    request: UpdateBoardRoomsRequest
  ): Promise<UpdateBoardRoomsResponse> {
    const data = UpdateBoardRoomsRequest.toBinary(request);
    const promise = this.rpc.request(
      "whiteboard.board.v1.BoardAPI",
      "UpdateBoardRooms",
      "application/protobuf",
      data
    );
    return promise.then((data) =>
      UpdateBoardRoomsResponse.fromBinary(data as Uint8Array)
    );
  }
}
