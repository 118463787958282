// @generated by protobuf-ts 2.7.0 with parameter client_none,generate_dependencies,long_type_number,output_typescript
// @generated from protobuf file "coupon/v1/coupon.proto" (package "coupon.v1", syntax proto3)
// tslint:disable
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MESSAGE_TYPE } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
import { DiscountType } from "../../base/v1/discount";
import { Timestamp } from "../../google/protobuf/timestamp";
/**
 * @generated from protobuf message coupon.v1.Coupon
 */
export interface Coupon {
    /**
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * @generated from protobuf field: string value = 2;
     */
    value: string;
    /**
     * @generated from protobuf field: bool enabled = 3;
     */
    enabled: boolean;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp active_since = 4;
     */
    activeSince?: Timestamp;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp active_till = 5;
     */
    activeTill?: Timestamp;
    /**
     * @generated from protobuf field: int32 max_usage = 6;
     */
    maxUsage: number;
    /**
     * @generated from protobuf field: int32 max_usage_per_user = 7;
     */
    maxUsagePerUser: number;
    /**
     * @generated from protobuf field: int32 max_users = 8;
     */
    maxUsers: number;
    /**
     * @generated from protobuf field: double discount_value = 9;
     */
    discountValue: number;
    /**
     * @generated from protobuf field: double minimum_value = 10;
     */
    minimumValue: number;
    /**
     * @generated from protobuf field: base.v1.DiscountType discount_type = 11;
     */
    discountType: DiscountType;
}
// @generated message type with reflection information, may provide speed optimized methods
class Coupon$Type extends MessageType<Coupon> {
    constructor() {
        super("coupon.v1.Coupon", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "value", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "enabled", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 4, name: "active_since", kind: "message", T: () => Timestamp },
            { no: 5, name: "active_till", kind: "message", T: () => Timestamp },
            { no: 6, name: "max_usage", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 7, name: "max_usage_per_user", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 8, name: "max_users", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 9, name: "discount_value", kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 10, name: "minimum_value", kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 11, name: "discount_type", kind: "enum", T: () => ["base.v1.DiscountType", DiscountType, "DISCOUNT_TYPE_"] }
        ]);
    }
    create(value?: PartialMessage<Coupon>): Coupon {
        const message = { id: "", value: "", enabled: false, maxUsage: 0, maxUsagePerUser: 0, maxUsers: 0, discountValue: 0, minimumValue: 0, discountType: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<Coupon>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Coupon): Coupon {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                case /* string value */ 2:
                    message.value = reader.string();
                    break;
                case /* bool enabled */ 3:
                    message.enabled = reader.bool();
                    break;
                case /* google.protobuf.Timestamp active_since */ 4:
                    message.activeSince = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.activeSince);
                    break;
                case /* google.protobuf.Timestamp active_till */ 5:
                    message.activeTill = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.activeTill);
                    break;
                case /* int32 max_usage */ 6:
                    message.maxUsage = reader.int32();
                    break;
                case /* int32 max_usage_per_user */ 7:
                    message.maxUsagePerUser = reader.int32();
                    break;
                case /* int32 max_users */ 8:
                    message.maxUsers = reader.int32();
                    break;
                case /* double discount_value */ 9:
                    message.discountValue = reader.double();
                    break;
                case /* double minimum_value */ 10:
                    message.minimumValue = reader.double();
                    break;
                case /* base.v1.DiscountType discount_type */ 11:
                    message.discountType = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Coupon, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* string value = 2; */
        if (message.value !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.value);
        /* bool enabled = 3; */
        if (message.enabled !== false)
            writer.tag(3, WireType.Varint).bool(message.enabled);
        /* google.protobuf.Timestamp active_since = 4; */
        if (message.activeSince)
            Timestamp.internalBinaryWrite(message.activeSince, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.Timestamp active_till = 5; */
        if (message.activeTill)
            Timestamp.internalBinaryWrite(message.activeTill, writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        /* int32 max_usage = 6; */
        if (message.maxUsage !== 0)
            writer.tag(6, WireType.Varint).int32(message.maxUsage);
        /* int32 max_usage_per_user = 7; */
        if (message.maxUsagePerUser !== 0)
            writer.tag(7, WireType.Varint).int32(message.maxUsagePerUser);
        /* int32 max_users = 8; */
        if (message.maxUsers !== 0)
            writer.tag(8, WireType.Varint).int32(message.maxUsers);
        /* double discount_value = 9; */
        if (message.discountValue !== 0)
            writer.tag(9, WireType.Bit64).double(message.discountValue);
        /* double minimum_value = 10; */
        if (message.minimumValue !== 0)
            writer.tag(10, WireType.Bit64).double(message.minimumValue);
        /* base.v1.DiscountType discount_type = 11; */
        if (message.discountType !== 0)
            writer.tag(11, WireType.Varint).int32(message.discountType);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message coupon.v1.Coupon
 */
export const Coupon = new Coupon$Type();
