// @generated by protobuf-ts 2.7.0 with parameter client_none,generate_dependencies,long_type_number,output_typescript
// @generated from protobuf file "whiteboard/board/v1/board.proto" (package "whiteboard.board.v1", syntax proto3)
// tslint:disable
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MESSAGE_TYPE } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
import { AccessType } from "../../../base/v1/access";
import { Timestamp } from "../../../google/protobuf/timestamp";
import { Orientation } from "../../../base/v1/whiteboard";
import { Preset } from "../../../base/v1/whiteboard";
/**
 * @generated from protobuf message whiteboard.board.v1.Board
 */
export interface Board {
    /**
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * @generated from protobuf field: string name = 2;
     */
    name: string;
    /**
     * @generated from protobuf field: bytes data = 3;
     */
    data: Uint8Array;
    /**
     * @generated from protobuf field: string preview_image_id = 4;
     */
    previewImageId: string;
    /**
     * @generated from protobuf field: string template_id = 5;
     */
    templateId: string;
    /**
     * @generated from protobuf field: base.v1.Preset preset = 6;
     */
    preset: Preset;
    /**
     * @generated from protobuf field: base.v1.Orientation orientation = 7;
     */
    orientation: Orientation;
    /**
     * @generated from protobuf field: bool archived = 8;
     */
    archived: boolean;
    /**
     * @generated from protobuf field: string description = 9;
     */
    description: string;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp created_at = 10;
     */
    createdAt?: Timestamp;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp updated_at = 11;
     */
    updatedAt?: Timestamp;
    /**
     * @generated from protobuf field: base.v1.AccessType access_type = 12;
     */
    accessType: AccessType;
}
/**
 * @generated from protobuf message whiteboard.board.v1.ListBoard
 */
export interface ListBoard {
    /**
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * @generated from protobuf field: string name = 2;
     */
    name: string;
    /**
     * @generated from protobuf field: string preview_image_id = 3;
     */
    previewImageId: string;
    /**
     * @generated from protobuf field: base.v1.Preset preset = 5;
     */
    preset: Preset;
    /**
     * @generated from protobuf field: base.v1.Orientation orientation = 6;
     */
    orientation: Orientation;
    /**
     * @generated from protobuf field: bool archived = 7;
     */
    archived: boolean;
    /**
     * @generated from protobuf field: string description = 8;
     */
    description: string;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp created_at = 9;
     */
    createdAt?: Timestamp;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp updated_at = 10;
     */
    updatedAt?: Timestamp;
    /**
     * @generated from protobuf field: base.v1.AccessType access_type = 11;
     */
    accessType: AccessType;
}
// @generated message type with reflection information, may provide speed optimized methods
class Board$Type extends MessageType<Board> {
    constructor() {
        super("whiteboard.board.v1.Board", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "data", kind: "scalar", T: 12 /*ScalarType.BYTES*/ },
            { no: 4, name: "preview_image_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "template_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "preset", kind: "enum", T: () => ["base.v1.Preset", Preset] },
            { no: 7, name: "orientation", kind: "enum", T: () => ["base.v1.Orientation", Orientation, "ORIENTATION_"] },
            { no: 8, name: "archived", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 9, name: "description", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 10, name: "created_at", kind: "message", T: () => Timestamp },
            { no: 11, name: "updated_at", kind: "message", T: () => Timestamp },
            { no: 12, name: "access_type", kind: "enum", T: () => ["base.v1.AccessType", AccessType, "ACCESS_TYPE_"] }
        ]);
    }
    create(value?: PartialMessage<Board>): Board {
        const message = { id: "", name: "", data: new Uint8Array(0), previewImageId: "", templateId: "", preset: 0, orientation: 0, archived: false, description: "", accessType: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<Board>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Board): Board {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                case /* string name */ 2:
                    message.name = reader.string();
                    break;
                case /* bytes data */ 3:
                    message.data = reader.bytes();
                    break;
                case /* string preview_image_id */ 4:
                    message.previewImageId = reader.string();
                    break;
                case /* string template_id */ 5:
                    message.templateId = reader.string();
                    break;
                case /* base.v1.Preset preset */ 6:
                    message.preset = reader.int32();
                    break;
                case /* base.v1.Orientation orientation */ 7:
                    message.orientation = reader.int32();
                    break;
                case /* bool archived */ 8:
                    message.archived = reader.bool();
                    break;
                case /* string description */ 9:
                    message.description = reader.string();
                    break;
                case /* google.protobuf.Timestamp created_at */ 10:
                    message.createdAt = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.createdAt);
                    break;
                case /* google.protobuf.Timestamp updated_at */ 11:
                    message.updatedAt = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.updatedAt);
                    break;
                case /* base.v1.AccessType access_type */ 12:
                    message.accessType = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Board, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* string name = 2; */
        if (message.name !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.name);
        /* bytes data = 3; */
        if (message.data.length)
            writer.tag(3, WireType.LengthDelimited).bytes(message.data);
        /* string preview_image_id = 4; */
        if (message.previewImageId !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.previewImageId);
        /* string template_id = 5; */
        if (message.templateId !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.templateId);
        /* base.v1.Preset preset = 6; */
        if (message.preset !== 0)
            writer.tag(6, WireType.Varint).int32(message.preset);
        /* base.v1.Orientation orientation = 7; */
        if (message.orientation !== 0)
            writer.tag(7, WireType.Varint).int32(message.orientation);
        /* bool archived = 8; */
        if (message.archived !== false)
            writer.tag(8, WireType.Varint).bool(message.archived);
        /* string description = 9; */
        if (message.description !== "")
            writer.tag(9, WireType.LengthDelimited).string(message.description);
        /* google.protobuf.Timestamp created_at = 10; */
        if (message.createdAt)
            Timestamp.internalBinaryWrite(message.createdAt, writer.tag(10, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.Timestamp updated_at = 11; */
        if (message.updatedAt)
            Timestamp.internalBinaryWrite(message.updatedAt, writer.tag(11, WireType.LengthDelimited).fork(), options).join();
        /* base.v1.AccessType access_type = 12; */
        if (message.accessType !== 0)
            writer.tag(12, WireType.Varint).int32(message.accessType);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message whiteboard.board.v1.Board
 */
export const Board = new Board$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListBoard$Type extends MessageType<ListBoard> {
    constructor() {
        super("whiteboard.board.v1.ListBoard", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "preview_image_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "preset", kind: "enum", T: () => ["base.v1.Preset", Preset] },
            { no: 6, name: "orientation", kind: "enum", T: () => ["base.v1.Orientation", Orientation, "ORIENTATION_"] },
            { no: 7, name: "archived", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 8, name: "description", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 9, name: "created_at", kind: "message", T: () => Timestamp },
            { no: 10, name: "updated_at", kind: "message", T: () => Timestamp },
            { no: 11, name: "access_type", kind: "enum", T: () => ["base.v1.AccessType", AccessType, "ACCESS_TYPE_"] }
        ]);
    }
    create(value?: PartialMessage<ListBoard>): ListBoard {
        const message = { id: "", name: "", previewImageId: "", preset: 0, orientation: 0, archived: false, description: "", accessType: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ListBoard>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListBoard): ListBoard {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                case /* string name */ 2:
                    message.name = reader.string();
                    break;
                case /* string preview_image_id */ 3:
                    message.previewImageId = reader.string();
                    break;
                case /* base.v1.Preset preset */ 5:
                    message.preset = reader.int32();
                    break;
                case /* base.v1.Orientation orientation */ 6:
                    message.orientation = reader.int32();
                    break;
                case /* bool archived */ 7:
                    message.archived = reader.bool();
                    break;
                case /* string description */ 8:
                    message.description = reader.string();
                    break;
                case /* google.protobuf.Timestamp created_at */ 9:
                    message.createdAt = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.createdAt);
                    break;
                case /* google.protobuf.Timestamp updated_at */ 10:
                    message.updatedAt = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.updatedAt);
                    break;
                case /* base.v1.AccessType access_type */ 11:
                    message.accessType = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListBoard, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* string name = 2; */
        if (message.name !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.name);
        /* string preview_image_id = 3; */
        if (message.previewImageId !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.previewImageId);
        /* base.v1.Preset preset = 5; */
        if (message.preset !== 0)
            writer.tag(5, WireType.Varint).int32(message.preset);
        /* base.v1.Orientation orientation = 6; */
        if (message.orientation !== 0)
            writer.tag(6, WireType.Varint).int32(message.orientation);
        /* bool archived = 7; */
        if (message.archived !== false)
            writer.tag(7, WireType.Varint).bool(message.archived);
        /* string description = 8; */
        if (message.description !== "")
            writer.tag(8, WireType.LengthDelimited).string(message.description);
        /* google.protobuf.Timestamp created_at = 9; */
        if (message.createdAt)
            Timestamp.internalBinaryWrite(message.createdAt, writer.tag(9, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.Timestamp updated_at = 10; */
        if (message.updatedAt)
            Timestamp.internalBinaryWrite(message.updatedAt, writer.tag(10, WireType.LengthDelimited).fork(), options).join();
        /* base.v1.AccessType access_type = 11; */
        if (message.accessType !== 0)
            writer.tag(11, WireType.Varint).int32(message.accessType);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message whiteboard.board.v1.ListBoard
 */
export const ListBoard = new ListBoard$Type();
